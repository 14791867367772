body, html {
	font-size: var(--base-font-size);
	height: 100%;
}

body {
	background: $light;
	max-height: 100vh;
}

.wrapperTime {
	overflow: hidden;
	height: 100%;
}

// Main Layout
main.main-content {
	flex-grow: 1;
	height: 100%;
	position: relative;
	display: block;
}

.mainHeader {
	background: $blue;
	color: $light;
	* {
		color: $light;
	}
	h1 {
		font-weight: normal;
	}
}

.tab-content {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
}

.appContainer {
	padding-left: 24px;
	padding-right: 24px;
	@media #{$hd}{
		padding-left: 48px;
		padding-right: 48px;
	}
	.card.mainWrap {
		border: 0;
		border-radius: 0;
	}
}

#wrapper {
	align-items: flex-start;
	&.Signin {
		> main {
			width: 100%;
		}
	}
	main.main-content {
		flex-grow: 1;
	}
}

nav#navbarBlur {
	margin: 0 !important;
	background: #fff;
	margin-bottom: 24px !important;
}

.badge {
	background: $blue;
	font-weight: normal;
}

.modal-dialog.modal-fullscreen {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
}

.ps {
	.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.text-xs {
	font-size: .8rem;
}

ul.r-nav {
	margin-bottom: 0;
}

.loader__message {
	@include font-size(18);
	font-weight: normal;
}

ol.breadcrumb {
	margin-left: 0;
	margin-bottom: 0;
}

.btn-primary {
	color: $light;
	&:hover {
		color: $light;
	}
}

nav.breadcrumb {
	padding-top: 4px;
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff; /* change if the mask should be a color other than white */
	z-index: 99999; /* makes sure it stays on top */
	.loader {
		width: 640px;
		height: 200px;
		max-width: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -320px;
		margin-top: -170px;
		text-align: center;
		font-weight: bold;
		padding: 12px;
		background: $light;
		.loadbarwrap {
			width: 100%;
			display: block;
			background: #e7f0fe;
			.loadbar {
				background: $link;
				height: 28px;
				display: block;
				transition: .3s all ease 0s;
			}
		}
		.loader__message {
			margin-bottom: 0;
			@include font-size(20);
		}
		.loader__figure {
			@include font-size(28);
			color: $link;
			font-weight: lighter;
		}
	}
}

.navbox.side {
	background: $bgbox;
	padding: 12px;
	@media #{$hd}{
		padding: 24px;
	}
	ul.quickactions {
		margin-left: 0;
		margin-bottom: 0;
		padding: 0;
		li {
			list-style: none;
			padding: 0;
			margin: 0;
			.btn {
				width: 100%;
				white-space: nowrap;
			}
		}
	}
}

.workDay .times .row .col strong.fs-5 {
	@media #{$large}{
		font-size: 1.5rem !important;
	}
}

.wordBox {
	position: absolute;
	bottom: 8%;
	text-align: left;
	left: 86px;
	h5 {
		@include font-size(40);
		font-weight: lighter;
	}
	.introline {
		font-weight: lighter;
		@include font-size(20);
	}
}

// Signup
main.signup {
	.card {
		border: 0;
		border-radius: 0;
		.card-header {
			background: transparent;
			border-bottom: 0;
			h1 {
				color: $blue;
			}
		}
		.input-group i {
			color: $blue;
		}
		.input-group {
			margin-bottom: 12px;
			.input-group-text {
				position: absolute;
				z-index: 99;
				height: 100%;
				background: transparent;
				& + input {
					padding-left: 42px;
					margin-bottom: 0;
				}
			}
		}
	}
}

#app .input-group .form-control {
	border: 0;
}

#app .input-group .input-group-text {
	border: 0;
}

.fixedMenu {
	text-align: center;
	z-index: 1000;
	position: fixed;
	bottom: 0;
	width: 100%;
	background: white;
	box-shadow: 0 -31px 9px 0 #fff;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			background: #EFEFEF;
			border-top: 2px solid #EFEFEF;
			text-align: center;
			display: block;
			float: left;
			width: 20%;
			span {
				font-size: 12px;
				display: block;
				padding-top: 5px;
				padding-bottom: 5px;
				cursor: pointer;
				i {
					font-size: 24px;
					&:before {
						color: #707070;
					}
				}
			}
			&.active {
				border-top-color: $blue;
				span, i {
					color: $link;
				}
				i:before {
					color: $link;
				}
			}
			&.specialButton {
				height: 53px;
				.wrapButton {
					transform: translateY(-31px);
					background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 41%, rgba(255, 255, 255, 1) 100%);
				}
			}
		}
	}
}

.Vue-Toastification__toast.Vue-Toastification__toast--success {
	background: $blue;
}

.card {
	@media #{$xlarge}{
		width: 724px;
		margin-left: auto;
		margin-right: auto;
	}
}

.card.overtime, .card.vacation {
	padding: 8px 16px !important;
	@include font-size(14);
	strong {
		@media #{$large}{
			@include font-size(24);
		}
	}
}

.card.vacation {
	background: $lightblue;
	h6 {
		color: $blue;
	}
}

.wrapButton {
	width: 60px;
	text-align: center;
	height: 62px;
	border-radius: 100%;
	overflow: hidden;
	line-height: 47px;
	margin-left: auto;
	margin-right: auto;
	.btn-round {
		color: $link;
		background: $lightblue;
		margin: 0;
		font-size: 25px;
		border-radius: 100%;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1607843137);
		position: relative;
		top: 5px;
		width: 48px;
		height: 50px;
		i:after {
			color: $link;
		}
	}
	&.pause {
		.btn-round {
			color: #6F42C1;
			background: #c6b3e6;
		}
	}
	&.stop {
		.btn-round {
			background: #e0a6a9;
			color: $red;
		}
	}
	&.new {
		.btn-round {
			background: #e0a6a9;
			color: $red;
			i:after {
				color: $red;
			}
		}
	}
	&.neutral {
		.btn-round {
			background: $bgbox;
			color: $base;
			i:after {
				color: $base;
			}
		}
	}
}

.dp__main {
	.dp__pointer {
		background: $lightblue;
		border: 0;
		padding-top: .6rem;
		padding-bottom: .6rem;
	}
	span.dp__input_icon {
		padding-left: 0.8rem;
	}
	.dp__clear_icon {
		filter: grayscale(1);
	}
}

.dropdown-menu.show {
	transform: translate(-37%, -3px);
	background: $lightblue;
	border: 0;
	box-shadow: $box-shadow;
	button.btn-close.float-end {
		position: absolute;
		right: 4px;
		top: 4px;
	}
}

.input-group select, .input-group textarea {
	background: $lightblue;
	margin-bottom: 0;
}

.input-group textarea {
	padding: 12px;
}

.day.card {
	background: $bgbox;
	padding: 8px 16px;
	border-radius: $br;
	margin-bottom: 12px;
	.day-content .time:last-child p {
		margin-bottom: 0 !important;
	}
	p, div {
		@include font-size(13);
		@media #{$medium}{
			@include font-size(16);
		}
		@media #{$hd}{
			@include font-size(20);
		}
	}
}

#app button.btn.btn-transparent.dropdown-toggle {
	padding: 0;
	&:after {
		display: none;
	}
}

.timeDisplay {
	p {
		margin-bottom: 0;
		font-weight: 600;
		@include font-size(28);
		@media #{$large}{
			@include font-size(40);
		}
	}
}

.Vue-Toastification__toast {
	bottom: 82px;
}

.dayheaderTop {
	font-weight: bold;
	color: $link;
}

.dashboard2 {
	&.infos {
		.card-body > .row > .col-6:first-child {
			border-right: 1px solid $h4;
		}
	}
}
