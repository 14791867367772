
.dp__pointer {
  text-indent: 24px;
  line-height: 24px;
}

.dp__cell_inner {
  text-indent: 0;
}

.day-header h3 {
  font-size: 14px;
}

.mainHeader h1 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 36px;
}

.mainHeader h2 {
  font-weight: normal;
  font-size: 20px;
}

footer a {
  color: #fff !important;
}

.headerBigImage {
  .container {
    position: relative;
    z-index: 301;
  }
}

.day-content {
  font-size: 14px;
}

h3 {
}

.day-content h4 {
  font-size: 16px;
  font-weight: normal;
}

.input-group {
  .dp__main.dp__theme_light.form-control {
    padding: 0;
  }
  .input-group-append {
    .btn.btn-primary {
      height: 100%;
      display: inline-flex;
      align-items: center;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}
.inputTimeWrapper{width: 500px; margin-left: auto; margin-right: auto;}
.dashboard2 {
  min-height: 83vh;
}
