@import 'variables';
@import 'grid';
@import 'mixins';
@import 'app';
@import 'typography';
.vue-universal-modal {
	z-index: 1000;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	.container {
		@media #{$medium}{
			max-width: 85%;
		}
	}
}

body {
	font-size: $font-size-base;
}

a {
	transition: .4s all;
}
#app{
	padding-bottom: 88px;
}
#app .btn {
	padding: 5px 12px;
	@media #{$medium}{
		padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
	}
}

label {
	margin-bottom: .5rem;
}

.nav-tabs {
	.nav-link {
		padding: 1rem;
		border-radius: 0;
		&.active {
			background-color: $secondary;
			color: $white;
		}
	}
}



.btn-secondary {
	color: $white;
	&:hover,
	&:focus {
		color: rgba($white, 0.95) !important;
	}
}

h1 {
	@media #{$large}{
		font-size: 3.75rem;
	}
	span {
		font-size: 70%;
	}
}

.card {
	transition: .4s all;
	&:hover,
	&:focus {
		box-shadow: 0 4px 24px rgba(0, 0, 0, .16);
	}
}

#app {
	background: #fff;
}

.mainContent {
	background: #ffffff;
}

.card.bl {
	margin-bottom: 24px;
}

.navigation {
	background: #fff;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
	.logo {
		img {
			height: 2.8rem;
		}
		span {
			text-transform: uppercase;
		}
	}
	.navbar {
		padding-top: 2px;
	}
	.logout-button {
		@media #{$large}{
			position: relative;
			line-height: 0;
			top: -2px;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			a {
				text-transform: uppercase;
				color: $secondary;
				&:hover,
				&:focus {
					color: $primary;
				}
			}
		}
	}
}

.header {
	background-color: $light;
	&.headerBigImage {
		padding-top: 48px;
		padding-bottom: 24px;
		min-height: 550px;
		@media #{$large}{
			padding-top: 16rem;
			padding-bottom: 16rem;
		}
	}
}

nav.tabNav {
	padding-top: 48px;
	padding-bottom: 48px;
	.nav-link {
		cursor: pointer;
	}
}

.contentTabs {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
}

.progress-bar {
	background-color: $white;
	box-shadow: -6px 25px 20px 0 rgba(128, 152, 213, .2);
	border-top: 1rem solid $secondary;
	.progress-icon {
		height: 2rem;
		width: 2rem;
		line-height: 2rem;
		text-align: center;
		svg {
			path {
				fill: $primary;
			}
		}
	}
}

.inputBlock {
	padding: 24px;
	margin-top: 24px;
	background: #eee;
}

@media #{$large}{
	span.points.btn {
		min-width: 134px;
		display: inline-block;
	}
	span.text-secondary.date.float-end {
		display: inline-block;
		min-width: 193px;
		text-align: center;
	}
}

footer {
	background-color: #eee;
	border-top: .5px solid #d3d7de;
	color: #757678;
	font-size: .75rem;
	a {
		color: #757678;
	}
}

.progressbar2 {
	.progress-item {
		.progress-clip {
			clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);

		}
	}
}

.dashboard2 {
	svg {
		path {
			fill: $secondary;
		}
	}
	.anchor {
		letter-spacing: 1.5px;
		.anchor-label {
			padding-top: 2rem;
			position: relative;
		}
	}
}

aside.cookie-comply {
	position: fixed;
	z-index: 99999;
	bottom: 15px;
	width: 1000px;
	max-width: 90%;
	left: 50%;
	transform: translateX(-50%);
	p {
		@include font-size(14);
	}
	.cookie-comply__actions {
		button {
			padding: 7px 25px;
		}
	}
}

.logoText {
	text-align: center;
	@include font-size(34);
	position: relative;
	color: $sketch;
	i {
		color: $link;
	}
	i.fa-duotone.fa-clock-three.fa-swap-opacity.fa-2xs{
		transform: translate(-6px, -6px)
	}
	strong {
		font-weight: 600;
		color: $link;
	}
}

.cookie-comply__modal {
	.cookie-comply__modal-content {
		padding: 24px;
		overflow: hidden;
	}
	z-index: 99999;
	header.cookie-comply__modal-header {
		padding-left: 24px;
	}
	.cookie-comply__back-arrow {
		top: 24px;
	}
	p {
		font-size: 14px;
	}
	h3 {
		@include font-size(18);
	}
	h2 {
		@include font-size(20);
	}
	footer {
		background-color: #fff;
	}
}
